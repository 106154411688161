import { CaseCountByService } from "api/overruns";
import { createContext, useContext, useState } from "react";

export type ElementSelected = {
  id?: number;
  name?: string;
  children?: ElementSelected[];
  parent?: number;
  metadata?: {
    alias?: string;
    events_count?: number | undefined;
    descendant_events_count?: number | undefined;
    case_count?: CaseCountByService | undefined;
    descendant_case_count?: CaseCountByService | undefined;
    root?: {
      name: string;
      alias?: string;
    };
  };
};

type GroupContextType = {
  elementSelected: ElementSelected;
  setElementSelected: (element: ElementSelected) => void;
};

const GroupContext = createContext<GroupContextType>({
  elementSelected: {},
  setElementSelected: (_) => {},
});

const GroupContextProvider = ({ children }) => {
  const [elementSelected, setElementSelected] = useState({});

  return (
    <GroupContext.Provider
      value={{
        elementSelected,
        setElementSelected,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};

function useGroupContext() {
  const context = useContext(GroupContext);
  if (!context) throw new Error("useGroupContext must be used within a GroupContextProvider");
  return context;
}

export { GroupContextProvider, useGroupContext };
