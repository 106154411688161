import { createContext, useContext, useState } from "react";

export const LabelingContext = createContext(null);

export const LabelingContextProvider = ({ children }) => {
  const [labelingData, setLabelingData] = useState(initialState);

  return (
    <LabelingContext.Provider
      value={{
        ...labelingData,
        updateLabelingData: (newLabelingData) =>
          setLabelingData({ ...labelingData, ...newLabelingData }),
      }}
    >
      {children}
    </LabelingContext.Provider>
  );
};

export function useLabelingContext() {
  const context = useContext(LabelingContext);
  if (!context) throw new Error("useLabelingContext must be used within a LabelingContextProvider");
  return context;
}

const initialState = {
  labelingPanelOpen: false,
  updateLabelingData: () => {},
  sensor: {},
  group: {},
  clickedLabelStatus: {},
};
