import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Board } from "./components/Board";

import * as S from "./App.styled";
import { KeycloakProvider } from "./context/KeycloakContext";
import { LabelingContextProvider } from "./context/LabelingContext";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "routeTree.gen";

import { Toaster } from "react-hot-toast";
import { GroupContextProvider } from "context/GroupContext";

const queryClient = new QueryClient();
const router = createRouter({ routeTree });

function App() {
  return (
    <>
      <Toaster />
      <S.BoardContainer>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <KeycloakProvider>
            <LabelingContextProvider>
              <GroupContextProvider>
                <Board>
                  <RouterProvider router={router} />
                </Board>
              </GroupContextProvider>
            </LabelingContextProvider>
          </KeycloakProvider>
        </QueryClientProvider>
      </S.BoardContainer>
    </>
  );
}

export default App;
