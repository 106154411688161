import { useContext, useEffect, useRef, useState } from "react";
import getEnv from "../helpers/env";
import Keycloak from "keycloak-js";
import { createContext } from "react";
import { SecurityBoard } from "../components/Postapp/SecurityBoard";
import { Spinner } from "components/Layout/Spinner/Spinner";

const initOptions = {
  url: getEnv("REACT_APP_KEYCLOAK_URL"),
  realm: getEnv("REACT_APP_KEYCLOAK_REALM"),
  clientId: getEnv("REACT_APP_KEYCLOAK_CLIENT_ID"),
};
export const keycloak = new Keycloak(initOptions);

const initValue = {
  keycloak: keycloak,
  getAccessToken: async () => {
    await keycloak.updateToken(5);
    return keycloak.token;
  },
  getIdentityToken: () => keycloak.idToken,
  getIdentity: () => keycloak.idTokenParsed,
};
export const KeycloakContext = createContext(initValue);

export const KeycloakProvider = ({ children }) => {
  const { keycloak, ...rest } = useContext(KeycloakContext);
  const [authLoading, setAuthLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  // for reasoning behind this hack check https://github.com/keycloak/keycloak/issues/19452#issuecomment-1503483795
  const didInit = useRef(false);

  useEffect(() => {
    if (didInit.current) {
      return;
    }

    didInit.current = true;
    keycloak
      .init({ onLoad: "login-required", checkLoginIframe: false })
      .catch((e) => setAuthError(e))
      .finally(() => setAuthLoading(false));
  }, [keycloak]);

  let content = children;
  if (authLoading) {
    content = <Spinner />;
  } else if (authError) {
    console.error(authError);
    content = <SecurityBoard />;
  }

  return (
    <KeycloakContext.Provider value={{ ...rest, keycloak }}>{content}</KeycloakContext.Provider>
  );
};
