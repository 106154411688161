import * as S from "./PostappDashboardContainer.styled";

export const SecurityBoard = () => {
  return (
    <S.SecurityBoard>
      <span>No access.</span>
      <span>You are not authorized!</span>
    </S.SecurityBoard>
  );
};
