import { createRootRoute, Outlet } from "@tanstack/react-router";
import NavBar from "components/Postapp/NavBar";
import React from "react";

const TanStackRouterDevtools =
  process.env.NODE_ENV === "development"
    ? React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      )
    : () => null; // Render nothing outside development

const Root = () => (
  <>
    <NavBar />
    <Outlet />
    <TanStackRouterDevtools />
  </>
);

export const Route = createRootRoute({
  component: Root,
});
