import { createStitches } from "@stitches/react";

const fontSizeRatio = 1.2;
const fontBaseSize = "1rem";

export const { keyframes, styled, theme } = createStitches({
  theme: {
    colors: {
      primary: "#727CF5",
      primaryLight: "#848EF6",
      primaryDark: "#404FF2",

      secondary: "#6C757D",
      secondaryLight: "#899197",
      secondaryIconGray: "#C2CFE0",
      secondaryTableGray: "#707683",

      background: "#FAFBFE",
      backgroundDark: "#e1e2e5",
      selectedBackground: "rgba(97, 105, 208, 0.20)",
      selectedBackgroundLight: "#727CF50D",
      navGrafanaBackground: "#464f5c",
      navIcon: "#B6BBC2",
      navIconLight: "#d3d6da",

      // https://yeun.github.io/open-color/
      indigo0: "#edf2ff",
      indigo1: "#dbe4ff",
      indigo2: "#bac8ff",
      indigo3: "#91a7ff",
      indigo4: "#748ffc",
      indigo5: "#5c7cfa",
      indigo6: "#4c6ef5",
      indigo7: "#4263eb",
      indigo8: "#3b5bdb",
      indigo9: "#364fc7",

      teal0: "#e6fcf5",
      teal1: "#c3fae8",
      teal2: "#96f2d7",
      teal3: "#63e6be",
      teal4: "#38d9a9",
      teal5: "#20c997",
      teal6: "#12b886",
      teal7: "#0ca678",
      teal8: "#099268",
      teal9: "#087f5b",

      pink0: "#fff0f6",
      pink1: "#ffdeeb",
      pink2: "#fcc2d7",
      pink3: "#faa2c1",
      pink4: "#f783ac",
      pink5: "#f06595",
      pink6: "#e64980",
      pink7: "#d6336c",
      pink8: "#c2255c",
      pink9: "#a61e4d",

      violet0: "#f3f0ff",
      violet1: "#e5dbff",
      violet2: "#d0bfff",
      violet3: "#b197fc",
      violet4: "#9775fa",
      violet5: "#845ef7",
      violet6: "#7950f2",
      violet7: "#7048e8",
      violet8: "#6741d9",
      violet9: "#5f3dc4",

      success: "#0ACF97",
      danger: "#FA5C7C",
      eventsOrange: "#F16B4D",
      dark: "#313A46",
      darkLight: "#6f757e",
      light: "#EEF2F7",
      white: "#FFFFFF",

      indeterminate: "#B6BBC2",
      indeterminateLight: "#c5c9ce",
      confirmedLabel: "#FA5C7C",
      confirmedLabelLight: "#fb7d96",
      irrelevantLabel: "#39AFD1",
      irrelevantLabelLight: "#61bfda",
    },
    fontSizes: {
      1: fontBaseSize,
      2: `calc(${fontBaseSize} * ${fontSizeRatio})`,
      3: `calc(${fontBaseSize} * ${Math.pow(fontSizeRatio, 2)})`,
      4: `calc(${fontBaseSize} * ${Math.pow(fontSizeRatio, 3)})`,
      5: `calc(${fontBaseSize} * ${Math.pow(fontSizeRatio, 4)})`,
      6: `calc(${fontBaseSize} * ${Math.pow(fontSizeRatio, 5)})`,
      7: `calc(${fontBaseSize} * ${Math.pow(fontSizeRatio, 6)})`,
    },
  },
  media: {
    sm: "(min-width: 576px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 992px)",
    xl: "(min-width: 1200px)",
    xxl: "(min-width: 1400px)",
  },
});
