declare global {
  interface Window {
    env?: Object;
  }
}

const getEnv = (key: string) => {
  if (process.env.NODE_ENV === "development")
    // if local node server (e.g. yarn start). Process is magic var from node
    return process.env[key];

  if (window.env)
    // windows is magic var from browser, .env field is defined by us
    return window.env[key];

  return undefined;
};

export default getEnv;
