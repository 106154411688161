import { CircularProgress } from "@mui/material";
import { styled } from "stitches.config";

const Loader = styled("div", {
  width: "100%",
  minHeight: "10rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const Spinner = () => (
  <Loader>
    <CircularProgress />
  </Loader>
);
