import { keyframes, styled } from "stitches.config";
import * as Accordion from "@radix-ui/react-accordion";
import * as Slider from "@radix-ui/react-slider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

export const DashboardModeButtonsContainer = styled("div", {
  width: "100%",
});

export const Filter = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "$primary",
  padding: "8px",
});

export const SecurityBoard = styled("div", {
  width: "100%",
  height: "100%",
  backgroundColor: "#F5F5F5",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "24px",
  flexDirection: "column",
  gap: "10px",
});

export const NavBar = styled("div", {
  width: "52px",
  backgroundColor: "$dark",
  paddingTop: "26px",
  paddingBottom: "26px",
  paddingRight: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",

  "& a:link, a:visited": {
    color: "inherit",
  },
});

export const NavBarLogo = styled("img", {
  width: "100%",
});

export const NavBarGrafanaIconsContainer = styled("div", {
  marginTop: "150px",
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  gap: "14px",
  alignItems: "center",
  width: "90%",
  color: "$navIcon",
  padding: "16px 0",

  "& svg": {
    fontSize: "32px",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.7)",
    padding: "2px",

    "&:hover": {
      color: "$navIconLight",
      cursor: "pointer",
    },
  },
});

export const NavBarIconsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  gap: "3.6rem",
  alignItems: "center",
  justifyContent: "center",
  width: "90%",
  color: "$navIcon",

  "& svg": {
    fontSize: "32px",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.7)",
    padding: "2px",

    "&:hover": {
      color: "$navIconLight",
      cursor: "pointer",
    },
  },
});

export const NavBarIconsGroup = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.2rem",
});

export const NavBarVersion = styled("div", {
  color: "gray",
  fontStyle: "italic",
  fontSize: "x-small",
  flexGrow: "0",
});

export const Logout = styled("div", {
  width: "100%",
  padding: "0.2rem",
  fontSize: "$4",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "$navIcon",
  cursor: "pointer",
  marginBottom: "1rem",
  backgroundColor: "transparent",
  "&:hover": {
    color: "$navIconLight",
  },
});

export const BoardRightSide = styled("div", {
  width: "100%",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "start",
  gap: "0.5rem",
  paddingBottom: "1.5rem",
});

export const DashboardTreeActiveFilterContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
  alignItems: "center",
});

export const FilterHeader = styled("div", {
  color: "$secondary",
  fontWeight: 700,
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
});

export const ActiveFilterName = styled("span", {
  color: "$secondary",
  fontWeight: "normal",
});

export const ActiveFilterValue = styled("span", {
  color: "$primary",
  fontWeight: "normal",
});

export const FilterIcon = styled(FilterAltIcon, {
  color: "$primary",
});

export const FilterDropdownIcon = styled(KeyboardArrowUpOutlinedIcon, {
  color: "$primary",
});

export const FilterRoot = styled(Accordion.Root, {
  width: "100%",
});

export const FilterItem = styled(Accordion.Item, {
  borderBottom: "1px solid $secondary",
  overflow: "hidden",
});

export const FilterTrigger = styled(Accordion.Trigger, {
  all: "unset",
  width: "100%",
  cursor: "pointer",

  '&[data-state="open"]': {
    "& > :first-child": {
      display: "flex",
    },
    "& > :last-child": {
      display: "none",
    },
  },

  '&[data-state="closed"]': {
    "& > :first-child": {
      display: "none",
    },
    "& > :last-child": {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
  },
});

export const FilterContent = styled(Accordion.Content, {
  display: "flex",
  flexDirection: "column",
  marginTop: "16px",
  fontWeight: 700,
  overflow: "hidden",

  '&[data-state="open"]': {
    animation: `${slideDown}`,
    animationDuration: "500ms",
    animationFillMode: "forwards",
  },
});

export const FilterScopeValue = styled("div", {
  "& > :first-child": {
    color: "$secondary",
  },

  "& > :last-child": {
    color: "$primary",
  },
});

export const SliderRoot = styled(Slider.Root, {
  display: "flex",
  alignItems: "center",
  position: "relative",
  marginTop: "20px",
});

export const SliderTrack = styled(Slider.Track, {
  height: "3px",
  backgroundColor: "$indeterminate",
  flexGrow: 1,
  position: "relative",
});

export const SliderRange = styled(Slider.Range, {
  position: "absolute",
  backgroundColor: "$primary",
  height: "100%",
});

export const SliderThumb = styled(Slider.Thumb, {
  display: "block",
  width: "20px",
  height: "20px",
  backgroundColor: "$primary",
  borderRadius: "10px",
  cursor: "grab",
});

export const ActionButtonsContainer = styled("div", {
  display: "flex",
  gap: "16px",
  justifyContent: "flex-end",
  margin: "20px 0",
});

export const Button = styled("button", {
  all: "unset",
  height: "24px",
  padding: "4px 8px",
  borderRadius: "2px",
  cursor: "pointer",

  variants: {
    action: {
      save: {
        backgroundColor: "$primary",
        color: "$light",
      },
      reset: {
        backgroundColor: "$light",
        color: "$secondary",
      },
    },
  },
});
